import React from 'react';
import { Alert } from 'react-bootstrap';

function CustomAlert(props) {
  const { variant, title, text, setShow, show } = props;
  return (
    <Alert show={show} variant={variant} onClose={()=>setShow(false)} dismissible>
      <Alert.Heading>{title}</Alert.Heading>
      <p>
        {text}
      </p>
    </Alert>
  );
}

export default CustomAlert;
