/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';

import CustomAlert from '../../components/CustomAlert';
import api from '../../utils/api';
import { mapImg } from '../../images';
import './styles.scss';
import { Formik } from 'formik';
import * as yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const schema = yup.object().shape({
  name: yup.string().required('Por escriba su nombre'),
  phone: yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'Por favor escriba un número de teléfono válido').required('Por favor escriba su número de teléfono'),
  email: yup.string().email('Por favor escriba una dirección de correo válida').required('Por favor escriba su email'),
});

export default function ContactPage() {
  const [successStatus, setSuccessStatus] = useState(false);
  
  return (
    <div className="contact">
      <div className="contact-form-wrapper">
        {
          successStatus && <CustomAlert show={successStatus} setShow={setSuccessStatus} variant="success" title="Sus datos fueron enviados correctamente." text="En unos momentos un representante nuestro se comunicará con usted." />
        }
        <Container>
          <Row className="align-items-center">
            <Col md={5} className="contact-info">
              <h5>ESTAMOS PRESTOS <br />A AYUDARTE</h5>
              <p>SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte.</p>
            </Col>
            <Col md={7}>
              <Formik
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    const valores = JSON.stringify(values, null, 2);
                    api.post('contact/', {
                      "params": {
                        "name": valores.name,
                        "phone": valores.phone,
                        "email": valores.email,
                        "comment": valores.comment,
                      }
                    }).then((res) => {
                      if (res.status === 200) {
                        setSuccessStatus(true);
                      }
                    });
                    actions.setSubmitting(false);
                  }, 1000);
                }}
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  comment: '',
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  submitForm,
                  isSubmitting
                }) => (
                  <Form className="contact-form" noValidate onSubmit={handleSubmit}>
                    <span>
                      <Form.Group as={Row} controlId="formHorizontalName">
                        <Form.Label column sm={3}>
                          Nombre
                        </Form.Label>
                        <Col sm={9}>
                          <div className="cont-input">
                            <Form.Control type="text" type="text"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.name} />
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback></div>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalPhone">
                        <Form.Label column sm={3}>
                          Teléfono
                        </Form.Label>
                        <Col sm={9}>
                          <div className="cont-input">
                            <Form.Control type="text" name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.phone} />
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback></div>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                          Email
                        </Form.Label>
                        <Col sm={9}>
                          <div className="cont-input">
                            <Form.Control type="email" name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.email} />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback></div>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalComments">
                        <Form.Label column sm={3}>
                          Comentarios
                        </Form.Label>
                        <Col sm={9}>
                          <Form.Control as="textarea" rows="7" name="comment" value={values.comment} onChange={handleChange}
                            onBlur={handleBlur} />
                        </Col>
                      </Form.Group>
                    </span>
                    <Form.Group as={Row}>
                      <Col sm={12}>
                        <Button className="float-right" variant="primary" type="submit" disabled={isSubmitting}>
                          ENVIAR
                          {isSubmitting && <FontAwesomeIcon icon={faSpinner} pulse />}
                        </Button>
                      </Col>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact-extra-info">
        <Container>
          <Row className="justify-content-center">
            <Col md={7}>
              <h5 className="contact-title-h5 text-center">VISITA <span>NUESTRAS OFICINAS</span></h5>
              <p className="text-center">SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid>
        <Row>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3826.853981147744!2d-71.5656539851874!3d-16.432240688657515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424b5527b1fe39%3A0x737b47303c8ee941!2sSky%20High%20SAC!5e0!3m2!1ses-419!2spe!4v1616433324415!5m2!1ses-419!2spe" width="600" height="450" allowfullscreen="" loading="lazy"></iframe>
        </Row>
      </Container>
    </div>
  )
}