import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Nav,
  Row,
  Col,
  Carousel,
  Button
} from 'react-bootstrap';
import ProductTile from '../../components/ProductTile';
import api from '../../utils/api';

import {
  catImg,
  truperImg,
  msaImg,
} from '../../images';
import './styles.scss';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textTransform: 'uppercase',
    color: theme.white,
  },
  itemtext: {
    color: '#fff',
    fontFamily: 'BebasNeue',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Homepage() {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [trendProducts, setTrendProducts] = useState([]);
  const [carouselProducts, setCarouselProds] = useState([]);
  const [RedirectCategory, setRedirectCategory] = useState(false);
  const [RedirectProduct, setRedirectProduct] = useState(false);

  const handleClick = (e, category) => {
    e.preventDefault();
    let categoriesCopy = JSON.parse(JSON.stringify(categories));
    for (const key of categoriesCopy) {
      if (key.id === category) {
        key.open = !key.open;
      }
    }
    setCategories(categoriesCopy);
  };

  async function fetchCategories() {
    try {
      const { data } = await api.post('categories/', {});
      for (const key of data.result) {
        key.open = false;
      }
      setCategories(data.result);
    } finally { }
  }

  async function fetchTrendProducts() {
    try {
      const { data } = await api.get('trend/products/');
      setTrendProducts(data);
    } finally { }
  }

  async function fetchCarouselProds() {
    try {
      const { data } = await api.get('carousel/products/');
      setCarouselProds(data);
    } finally { }
  }

  useEffect(() => {
    fetchCategories();
    fetchTrendProducts();
    fetchCarouselProds();
  }, []);

  const handleRedirectCategory = (categoryId) => {
    setRedirectCategory(categoryId);
  };

  if (RedirectCategory) {
    return <Redirect to={{ pathname: `/category/${RedirectCategory}`, state: { categoryId: RedirectCategory } }} push />;
  }

  const handleRedirectProduct = (categoryId) => {
    setRedirectProduct(categoryId);
  };

  if (RedirectProduct) {
    return <Redirect to={{ pathname: '/lista-productos', state: { categoryId: RedirectProduct, title: "Productos" } }} />;
  }

  return (
    <>
      <div className="carousel-wrapper">
        <Container>
          <Row>
            <Col md={3} className="side-navigation">
              <Nav className="flex-column">
                {/* <Nav.Link>Nuestras Categorías</Nav.Link>
                  {
                    categories.map((category) => <Nav.Link key={category.id} href="/productos" className="sub-link">{category.name}</Nav.Link>)
                  } */}
              </Nav>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader className={classes.itemtext}>Nuestras Categorias</ListSubheader>
                }
                className={classes.root}
              >
                {
                  categories.map((category) => (
                    <div key={category.id}>
                      <ListItem button className="sub-link" key={category.id}>
                        <ListItemText primary={category.name} classes={{ primary: classes.itemtext }} onClick={() => handleRedirectCategory(category.id)} />
                        {category.child_id && category.open ? <ExpandLess onClick={(e) => handleClick(e, category.id)} /> : <ExpandMore onClick={(e) => handleClick(e, category.id)} />}
                      </ListItem>
                      <Collapse in={category.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {
                            category.child_id.map((category_child) => (
                              <ListItem button onClick={() => handleRedirectProduct(category.id)} className={classes.nested} key={category_child.id}>
                                <ListItemText primary={category_child.name} classes={{ primary: classes.itemtext }} />
                              </ListItem>
                            ))
                          }
                        </List>
                      </Collapse>
                    </div>
                  ))
                }
              </List>
            </Col>
            <Col md={9} className="p-0">
              <Carousel aria-hidden="true" prevIcon={<FontAwesomeIcon icon={faChevronLeft} />} nextIcon={<FontAwesomeIcon icon={faChevronRight} />}>
                {
                  carouselProducts.map((carouselProd) => (
                    <Carousel.Item key={carouselProd.id}>
                      <img
                        className="d-block w-100"
                        src={carouselProd.url_image}
                        height="400"
                        alt="First slide"
                      />
                      <Carousel.Caption style={{ opacity: 0.75 }}>
                        <img src={carouselProd.brand[0].url_image} height="40" alt="brand" />
                        <h5>{carouselProd.name}</h5>
                        <p>{carouselProd.description_sale}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))
                }
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="align-items-center products-resume">
          <Col md={3} className="side-label">
            <h5 className="side-title">RESPALDADOS POR LAS <span>MEJORES MARCAS</span></h5>
            <p>
              SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte.
            </p>
            <img height="40" src={catImg} alt="logo" />
            <img height="70" src={msaImg} alt="logo" />
            <img height="70" src={truperImg} alt="logo" />
          </Col>
          <Col md={9} className="products-items">
            <h5 className="text-left">PRODUCTOS DESTACADOS</h5>
            <div className="products-list">
              {
                trendProducts.map((product) => {
                  return (
                    <ProductTile
                      key={product.id}
                      imgBackground={product.url_image}
                      title={product.name}
                      logo={product.brand[0].url_image}
                      description={product.description_sale}
                    />
                  )
                })
              }
            </div>
            <Row className="align-items-center">
              <Col md={7}>
                <p className="text-left">
                  SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte.
                </p>
              </Col>
              <Col md={5}>
                <Button variant="primary" href="/category">
                  CONOCE TODAS NUESTRAS CATEGORÍAS
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}