import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { whiteLogo } from '../../images';
import './styles.scss';

export default function Footer(props) {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col>
            <img src={whiteLogo} height="60" className="footer-logo" alt="white-logo" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav className="justify-content-center footer-navigation" activeKey="/home">
              <Nav.Item>
                <Nav.Link href="/">Inicio</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/nosotros">Nosotros</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/category">Productos</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/contacto">Contacto</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={12} className="text-footer">
            <p className="text-left text-footer">SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte.</p>
          </Col>
          <Col lg={3} md={6} className="footer-container-social">
            <a href="https://web.facebook.com/SKY-HIGH-109738504077032">
              <span className="footer-social">
                <FontAwesomeIcon icon={faFacebookF} size="lg" />
              </span>
            </a>
            <span className="footer-social">
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </span>
            <span className="footer-social">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </span>
          </Col>
          <Col lg={4} md={6} className="footer-links">
            <Nav>
              <Nav.Item>
                <Nav.Link>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </span>
                  {props.street2}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faPhoneAlt} className="fa-fw" />
                  </span>
                  CENTRAL TELEFÓNICA: {props.phone}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={`mailto:${props.email}`}>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  {props.email}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-footer copyright">
              &copy; SKY HIGH PERÚ
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
