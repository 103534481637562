import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Button, Row, Col } from 'react-bootstrap';

import {
  catImg,
  truperImg,
  msaImg
} from '../../images';
import './styles.scss';

function CategoryItem(props) {
  const { title, description, image, prodId} = props;
  const [redirectProduct, setRedirectProduct] = useState(false);

  const redirectToProducts = event => {
    setRedirectProduct(true);
  };

  if (redirectProduct) {
    return <Redirect to={{pathname: '/lista-productos', state: {categoryId: prodId, title: title}}} push />;
  }

  return (
    <Card className="product-item-wrapper">
      <Row className="align-items-center">
        <Col md={7}>
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              {description}
            </Card.Text>
            <div className="product-item-footer">
              <div className="product-item-logos">
                <img src={catImg} alt="small-logo"/>
                <img src={truperImg} alt="small-logo" />
                <img src={msaImg} alt="small-logo" />
              </div>
              <Button variant="primary" onClick={redirectToProducts}>VER MÁS</Button>
            </div>
          </Card.Body>
        </Col>
        <Col md={5}>
          <Card.Img src={image} alt="product" />
        </Col>
        <Col xs={12} className="show-on-mobile">
        <Button variant="primary" onClick={redirectToProducts}>VER MÁS</Button>
        </Col>
      </Row>
    </Card>
  )
}

export default CategoryItem;
