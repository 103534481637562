import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faChartLine } from '@fortawesome/free-solid-svg-icons';

import api from '../../utils/api';
import { planningImg } from '../../images';
import './styles.scss';

function AboutUsPage() {
  const [data, setData] = useState([]);

  async function fetchData() {
    try {
      const { data } = await api.get('company/');
      setData(data[0]);
    } finally {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="about-us">
      <div className="about-us-banner">
        <Container>
          <Row className="justify-content-end align-items-center">
            <Col md={3}>
              <h5 className="about-us-h5 text-right">SOBRE <br/><span>NOSOTROS</span></h5>
              <p className="text-right">{data.about_text}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={7}>
            <Row className="align-items-center experience-container">
              <Col md={4}>
                <h5 className="about-us-h5">LA EXPERIENCIA <br/><span>NOS RESPALDA</span></h5>
              </Col>
              <Col md={8}>
                <p>
                  {data.about_text}
                </p>
              </Col>
            </Row>
            <Row className="mision-vision-container">
              <Col md={6}>
                <FontAwesomeIcon icon={faUserTie} size="3x" className="mision-vision-icon" />
                <h5 className="about-us-h5 text-center">NUESTRA <br/><span>MISIÓN</span></h5>
                <p className="text-justify">
                  {data.mision}
                </p>
              </Col>
              <Col md={6}>
                <FontAwesomeIcon icon={faChartLine} size="3x"  className="mision-vision-icon" /> 
                <h5 className="about-us-h5 text-center">NUESTRA <br/><span>VISIÓN</span></h5>
                <p className="text-justify">
                  {data.vision}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={5} className="planning-container">
            <img src={planningImg} alt="planning" />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AboutUsPage;
