import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../components/Loader';
import api from '../../utils/api';
import ProductBigTile from '../../components/ProductBigTile';
import './styles.scss';

export default function ListProductsPage(props) {
  const { searchTerm, categoryId, title } = props.location.state;

  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(false);

  async function fetchProducts() {
    setLoader(true);
    try {
      let url = '';
      let params = {};
      if (searchTerm) {
        url = 'products/';
        params = {
          "query": searchTerm,
        };
      }
      else {
        url = `products/${categoryId}`;
        params = {
          "query": searchTerm,
        };
      }
      const { data } = await api.post(url, {
        "params": params        
      });
      console.log(data)
      setProducts(data.result);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="list-of-products">
      { loader && <Loader />}
      <Container>
        <Button className="btn-back" onClick={props.history.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} className="btn-icon-left" />
          Ir Atrás
        </Button>
        <h5 className="text-left">{title}</h5>
        <Row>
          {
            products.map((product) => {
              return (
                <Col lg={3} md={6} key={product.id}>
                  <div className="cont-card">
                    <ProductBigTile
                      imgBackground={product.url_image}
                      title={product.name}
                      logo={product.brand[0] ? product.brand[0].url_image : ''}
                      description={product.description_sale}
                    />
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </div>
  )
}
