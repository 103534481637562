import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import api from '../../utils/api';

import Header from '../Header';
import Footer from '../Footer';
import Homepage from '../../views/Homepage';
import CategoryPage from '../../views/CategoryPage';
import AboutUsPage from '../../views/AboutUsPage';
import ContactPage from '../../views/ContactPage';
import ListProductsPage from '../../views/ListProductsPage';

function App() {
  const [data, setData] = useState({});

  async function fetchData() {
    try {
      const { data } = await api.get('infocompany/');
      setData(data[0]);
    } finally {}
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header  {...data}/>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route path="/nosotros" component={AboutUsPage} />
        <Route path="/category" component={CategoryPage} />
        <Route path="/category/:categoryId" component={CategoryPage} />
        <Route path="/contacto" component={ContactPage} />
        <Route path="/lista-productos" component={ListProductsPage} />
      </Switch>
      <Footer {...data}/>
    </>
  );
}

export default App;
