import React from 'react';
import { Spinner } from 'react-bootstrap';

import './styles.scss';

function Loader() {
  return (
    <div className="loader-wrapper">
      <Spinner animation="border" role="status">
        <span className="sr-only">Cargando...</span>
      </Spinner>
      <br />
      <h4>Cargando...</h4>
    </div>
  )
}

export default Loader;
