import React from 'react';
import { Card } from 'react-bootstrap';

import './styles.scss';

function ProductTile(props) {
  const { title, description, logo, imgBackground } = props;
  return (
    <Card className="text-white tile-wrapper">
      <Card.Img src={imgBackground} alt="Card image" />
      <Card.ImgOverlay className="tile-overlay">
        <Card.Title>{title}</Card.Title>
        <Card.Text className="tile-description">
          {description}
        </Card.Text>
        <Card.Text className="tile-footer">
          <img
            src={logo}
            alt="logo"
          />
          <span></span>
        </Card.Text>
      </Card.ImgOverlay>
    </Card>
  )
}

export default ProductTile;
