import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { mainLogo } from '../../images';
import SearchField from '../SearchField';
import './styles.scss';

function Header(props) {
  return (
    <header>
      <Navbar bg="primary" variant="dark" className="top-header">
        <Container>
          <Nav className="mr-auto">
            <Navbar.Text>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                {props.street2}
            </Navbar.Text>
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faPhoneAlt} className="fa-fw" />
                </span>
                CENTRAL TELEFÓNICA: {props.phone}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={`mailto:${props.email}`}>
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                {props.email}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
      
      <Navbar variant="light" expand="lg" className="main-header">
        <Container className="p-0">
          <Navbar.Brand href="/">
            <img
              src={mainLogo}
              height="70"
              className="d-inline-block align-top logo"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <SearchField />
            <Nav>
              <NavLink exact to="/" activeClassName="active" className="nav-link">Inicio</NavLink>
              <NavLink to="/nosotros" activeClassName="active" className="nav-link">Nosotros</NavLink>
              <NavLink to="/category" activeClassName="active" className="nav-link">Productos</NavLink>
              <NavLink to="/contacto" activeClassName="active" className="nav-link">Contacto</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header;
