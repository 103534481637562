import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { InputGroup, FormControl, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../components/Loader';

function SearchField() {
  const [searchTerm, setSearchTerm] = useState("");
  const [redirectToResults, setRedirect] = useState(false);

  async function handleSearch(e) {
    e.preventDefault();
    setRedirect(true);
  }

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  if (redirectToResults) {
    return <Redirect to={{pathname: '/lista-productos', state: {searchTerm: searchTerm, title:"Resultados de la Búsqueda"}}} />;
  }
  
  return (
    <>
      <Form className="search-field">  
        <InputGroup className="justify-content-end">
          <FormControl
            type="text"
            placeholder="Buscar un producto..."
            value={searchTerm}
            onChange={handleChange}
          />
          <InputGroup.Append>
            <Button type="submit" variant="outline-secondary" onClick={handleSearch} disabled={!searchTerm}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </>
  )
}

export default SearchField;
