import React from 'react';
import { Card } from 'react-bootstrap';

import './styles.scss';

function ProductBigTile(props) {
  const { title, description, logo, imgBackground } = props;
  return (
    <Card className="text-white big-tile-wrapper">
      <Card.Img src={imgBackground} alt="Card image" />
      <Card.ImgOverlay className="tile-overlay">
        <Card.Title>{title}</Card.Title>
        <Card.Text className="tile-description">
          {description}
        </Card.Text>
          {logo ? 
          <Card.Text className="tile-footer logo-product"><img
            src={logo}
            alt="logo"
          /></Card.Text> : ''}
      </Card.ImgOverlay>
    </Card>
  )
}

export default ProductBigTile;
