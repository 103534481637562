import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import CategoryItem from '../../components/CategoryItem';

import api from '../../utils/api';
import './styles.scss';

export default function CategoryPage(props) {
  const { categoryId } = props.location.state || {};
  const [categories, setCategories] = useState([]);

  async function fetchCategories() {
    try {
      const { data } = await api.post('categories/', {
        "params": {
          "query": categoryId,
        }
      });
      setCategories(data.result);
    } finally {}
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="products">
      <div className="products-banner">
        <Container>
          <Row className="justify-content-end align-items-center">
            <Col md={3}>
              <h5 className="products-h5 text-right">NUESTROS <br/><span>PRODUCTOS</span></h5>
              <p className="text-right">SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="products-list-items">
        <Row className="justify-content-md-center products-main-text-container">
          <Col md={7}>
            <h5 className="products-h5">RESPALDADO POR LAS <span>MEJORES MARCAS</span></h5>
            <p>
            SKY HIGH se preocupa en tu empresa. Tenemos todos los productos para el rubro industrial, minero, constructor, agricultor, tecnológico, etc. Contamos con todo lo que puedas imaginar dentro de las mejores marcas en el mercado. Para cualquier duda contactamos, estamos para servirte.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              categories.map((category) => (
                <CategoryItem
                  key={category.id}
                  prodId={category.id}
                  title={category.name}
                  description={category.category_description}
                  image={category.url_image}
                />
              ))
            }
          </Col>
        </Row>
      </Container>
      <div className="products-contact">
        <Container>
          <Row>
            <Col md={6} className="contact-text">
              <h5 className="products-h5">¿QUIERES RECIBIR UNA <span>COTIZACIÓN?</span></h5>
            </Col>
            <Col md={6} className="text-center">
              <Button variant="outline-primary" href="/contacto">
                CONTÁCTANOS
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}